import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import cx from "clsx"

import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import TextInfoContent from "@mui-treasury/components/content/textInfo"
import { useBlogTextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/blog"
import { useOverShadowStyles } from "@mui-treasury/styles/shadow/over"

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    marginBottom: "3.5rem",
    borderRadius: spacing(2), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    maxWidth: 500,
    marginLeft: "auto",
    overflow: "initial",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: spacing(2),
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
    },
  },
  media: {
    width: "88%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: "48%",
    borderRadius: spacing(2),
    backgroundColor: "#fff",
    position: "relative",
    [breakpoints.up("md")]: {
      width: "100%",
      marginLeft: spacing(-12),
      marginTop: 0,
      transform: "translateX(-8px)",
    },
    "&:after": {
      content: '" "',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(to bottom right, #F2F2F3, #37383A)",
      backgrounSize: "contain",
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: "initial",

    borderRadius: 100,

    paddingLeft: 24,
    paddingRight: 24,
  },
  lien: {
    color: "#ffffff",
    textDecoration: "none",
  },
}))

const BlogCard = ({ post }) => {
  const styles = useStyles()
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles()
  const shadowStyles = useOverShadowStyles()

  const description = post.frontmatter.description
  const img = post.frontmatter.img
  const title = post.frontmatter.title || post.fields.slug
  return (
    <>
      <Card className={cx(styles.root, shadowStyles.root)}>
        {img ? (
          <CardMedia className={styles.media} image={img} />
        ) : (
          <CardMedia
            className={styles.media}
            image="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/css/css.png"
          />
        )}

        <CardContent>
          <TextInfoContent
            classes={contentStyles}
            overline={post.frontmatter.date}
            heading={title}
            body={description}
          />
          <Button variant="contained" color="primary" className={styles.cta}>
            <Link className={styles.lien} to={post.fields.slug} itemProp="url">
              Lire la suite
            </Link>
          </Button>
        </CardContent>
      </Card>
    </>
  )
}

export default BlogCard
